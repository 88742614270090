
.live-popularity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: #fff;
    font-size: 18px;
    .icon {
        font-size: 22px;
        line-height: 28px;
        margin-right: 9px;
    }
    .item .hint-box,
    .item .right {
        width: 108px;
    }
    .time-box {
        display: flex;
        justify-content: space-between;
        width: 80%;
        .item {
            display: flex;
            .time {
                margin-top: 10px;
                color: #ff3e6c;
            }
            .not-start-time {
                color: #fff;
            }
        }
    }
    .popularity {
        display: flex;
        width: 80%;
        .right-popularity {
            flex: 1;
            .info-list {
                margin-top: 20px;
                .item {
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    margin-bottom: 25px;
                    .num-box {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        font-size: 24px;
                        color: #4b45ff;
                        .rise,
                        .go-down {
                            margin-left: 15px;
                            font-size: 25px;
                            color: #ff3e6c;
                        }
                        .go-down {
                            color: #12c1c1;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
