
#live-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
    }
}
#live-video-container-box .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1 !important;
}
